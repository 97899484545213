import { Inject, Optional, PLATFORM_ID } from '@angular/core';
import { TokenStorageService, AuthService, SocialAuthService } from '@triskele/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CpUserData } from '../models/user-data';
import { Router } from '@angular/router';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

export class LayoutBaseComponent {
  faSearch = faSearch;
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public tokenStorage: TokenStorageService<CpUserData>,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private router: Router,
    private appInsightsService: AppInsightsService,
    platformId: Object,
    request: Request
  ) {
    const domain = request && isPlatformServer(platformId) ?
      `${request.protocol}://${request.get('host')}` : '../../..';

    this.matIconRegistry
      .addSvgIcon(
        'cp-home',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/home.svg'
        )
      )
      .addSvgIcon(
        'cp-about',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/info.svg'
        )
      )

      .addSvgIcon(
        'cp-profile',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/my-account.svg'
        )
      )
      .addSvgIcon(
        'cp-package',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/package.svg'
        )
      )
      .addSvgIcon(
        'cp-money-bag',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/money-bag.svg'
        )
      )
      .addSvgIcon(
        'cp-list',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/shopping-list.svg'
        )
      )
      .addSvgIcon(
        'cp-logo',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/logo-icon.svg'
        )
      )
      .addSvgIcon(
        'cp-question',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/question.svg'
        )
      )
      .addSvgIcon(
        'cp-policy',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/shield.svg'
        )
      )
      .addSvgIcon(
        'cp-notif',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/bell.svg'
        )
      )
      .addSvgIcon(
        'cp-speedometer',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/speedometer.svg'
        ))
      .addSvgIcon(
        'cp-terms',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/terms-icon.svg'
        )
      ).addSvgIcon(
        'cp-star',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/favorites-button.svg'
        ))
      .addSvgIcon(
        'cp-deliveries',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/what-I-deliver.svg'
        ))
      .addSvgIcon(
        'cp-shipment-requests',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/my-shipment-requests.svg'
        ))
      .addSvgIcon(
        'cp-add-request',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          domain + '/assets/svg/add-request.svg'
        )).addSvgIcon(
          'cp-deliver-shipment',
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            domain + '/assets/svg/shouter.svg'
          ));
  }

  logout() {
    this.socialAuthService.signOut();
    this.authService
      .removeToken(this.tokenStorage.getUserData().userId, environment.clientId)
      .subscribe(() => { this.router.navigate(['auth/login']); });
    this.tokenStorage.signOut();
    if(this.appInsightsService)
    this.appInsightsService.clearAuthenticatedUserContext();
  }
}
