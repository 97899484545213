import { Injectable } from '@angular/core';
import { QueryService } from '@triskele/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserProfileDto } from '../models/user-profile-dto.model';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class UserService extends QueryService<UserProfileDto> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.mainApiUrl + '/api/Users');
  }

  public getMyProfile(): Observable<UserProfileDto> {
    return this.httpClient.get<UserProfileDto>(this.baseUrl + '/MyProfile');
  }
  public editMyProfile(item: UserProfileDto): Observable<any> {
    return this.httpClient.put(this.baseUrl + '/MyProfile', item);
  }
  public skipMakeMoneydesc(): Observable<any> {
    return this.httpClient.put(this.baseUrl + '/SkipMakeMoneyDesc', null);
  }
  public getProfile(userId: string): Observable<UserProfileDto> {
    return this.httpClient.get<UserProfileDto>(this.baseUrl + '/Profile/' + userId);
  }
  public editPhone(phone: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/Phone?phoneNo=' + phone, {});
  }
  public editEmail(email: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/Email?email=' + email, {});
  }
  public editName(fullName: string): Observable<any> {
    return this.httpClient.put<any>(this.baseUrl + '/Name?fullName=' + fullName, {});
  }
  public sendWelcomeEmail(): Observable<any> {
    return this.httpClient.post<any>(this.baseUrl + '/SendEmail', {});
  }
}
