import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppBarIconService {

  private iconSource = new BehaviorSubject(false);
  currentIcon = this.iconSource.asObservable();

  constructor() { }

  changeIcon(back: boolean) {
    this.iconSource.next(back);
  }

}
