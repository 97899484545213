import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TokenStorageService, AuthService, SocialAuthService } from '@triskele/common';
import { Component, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { fadeAnimation } from '../animations/fade.animation';
import { CpUserData } from '../models/user-data';
import { Router } from '@angular/router';
import { LayoutBaseComponent } from './layout-base.comonent';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { Request } from 'express';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-static-layout',
  templateUrl: './static-layout.component.html',
  animations: [fadeAnimation]
})
export class StaticLayoutComponent extends LayoutBaseComponent {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    tokenStorage: TokenStorageService<CpUserData>,
    authService: AuthService,
    socialAuthService: SocialAuthService,
    router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject(REQUEST) request: Request,
    @Optional() appInsightsService?: AppInsightsService
    ) {
    super(matIconRegistry, domSanitizer, tokenStorage, socialAuthService, authService, router, appInsightsService, platformId, request);
  }
  onActivate(event) {
    const sideNav = this.document.getElementsByTagName('mat-sidenav-content')[0];
    sideNav.scrollTo(0, 0);
  }
}
