import { Injectable } from '@angular/core';
import { ErrorLogService } from '@triskele/common';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

@Injectable({
  providedIn: 'root'
})
export class AppInsightErrorLogService extends ErrorLogService {

  constructor(private appInsightsService: AppInsightsService) {
    super();
  }
  public log(error: Error) {
    if (this.appInsightsService)
    this.appInsightsService.trackException(error);
  }
}
