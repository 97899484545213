
import { LayoutModule } from '@app/shared/layout.module';
import { NgModule, Optional, SkipSelf, NgZone, PLATFORM_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AnimationsService } from './animations/animations.service';
import { TitleService } from './title/title.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader, MissingTranslationHandler, TranslateParser } from '@ngx-translate/core';
import {
  TrsMissingTranslationHandler, JsonApiService, NotificationServiceBase, DynaFormBuilder, FluentFormBuilder,
  EnvironmentBase, AuthInterceptor, CaptchaServiceBase, ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY,
  SocialLoginModule, SocialAuthServiceConfig, LoginOpt, GoogleLoginProvider, FacebookLoginProvider
} from '@triskele/common';
import { environment } from '@env/environment';
import { ToastrNotificationService } from './notifications/toastr-notification.service';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { UpdateService } from './update.service';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { Title } from '@angular/platform-browser';
import { NotifService } from './notif.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/locale/', '.json');
}

export function socialConfig() {
  const config = new SocialAuthServiceConfig([]);

  const googleLoginOptions: LoginOpt = {
    fetch_basic_profile: true,
    scope: 'email profile openid',
  };
  config.providers.set(
    GoogleLoginProvider.PROVIDER_ID,
    new GoogleLoginProvider(environment.googleApiClientId, googleLoginOptions)
  );


  config.providers.set(
    FacebookLoginProvider.PROVIDER_ID,
    new FacebookLoginProvider(environment.facebookClientId)
  );

  // const linkedInClientId = configService.get(
  //   'applicationSettings:LinkedInApiClientId'
  // );
  // if (linkedInClientId) {
  //   config.providers.set(
  //     LinkedInLoginProvider.PROVIDER_ID,
  //     new LinkedInLoginProvider(linkedInClientId, false, 'en_US')
  //   );
  // }

  return config;
}

@NgModule({
  imports: [
    /* 3rd party libraries */
    CommonModule,
    HttpClientModule,
    LayoutModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TrsMissingTranslationHandler,
        deps: [JsonApiService, TranslateParser]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      closeButton: true
    }),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    LottieModule.forRoot({ player: playerFactory }),
    LoadingBarHttpClientModule,
    SocialLoginModule
  ],
  declarations: [

  ],
  providers: [
    { provide: EnvironmentBase, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: SecurityInterceptor,
    //   multi: true
    // },
    AnimationsService,
    TitleService,
    FluentFormBuilder,
    DynaFormBuilder,
    {
      provide: NotificationServiceBase,
      useClass: ToastrNotificationService
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    // Add captcha service
    {
      provide: CaptchaServiceBase,
      useClass: ReCaptchaV3Service,
      deps: [NgZone, RECAPTCHA_V3_SITE_KEY, PLATFORM_ID]
    },
    UpdateService,
    Title,
    NotifService,
    // Add social logins
    {
      provide: SocialAuthServiceConfig,
      useFactory: socialConfig
    },
  ],
  exports: []
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
