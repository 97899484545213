import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { NotifService } from './core/notif.service';
import { environment } from '@env/environment';
import { TokenStorageService, NotificationServiceBase, LoginResponse, AuthService, LocalStorage } from '@triskele/common';
import { UserProfileDto } from './+user/shared/models/user-profile-dto.model';
import { CpUserData } from './shared/models/user-data';
import { UserService } from './+user/shared/services/user.service';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IosInstallComponent } from './shared/ios-install/ios-install.component';
import { UserNotificationToken } from './shared/models/user-notification-token.model';
import { UserNotificationService } from './shared/services/user-notification-token.service';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  public title = 'ComfyPost';

  constructor(private afMessaging: AngularFireMessaging,
    private notifService: NotifService,
    private notificationService: NotificationServiceBase,
    private userNotificationService: UserNotificationService,
    tokenStorage: TokenStorageService<CpUserData>,
    userService: UserService,
    authService: AuthService,
    router: Router,
    private bottomSheet: MatBottomSheet,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(LocalStorage) private localStorage: LocalStorage
  ) {
    if (tokenStorage.getRefreshToken() && (!tokenStorage.getToken()
      || (tokenStorage.getToken() && tokenStorage.isExpired()) || !tokenStorage.getUserData())) {

      authService
        .refreshToken(environment.clientId, tokenStorage.getRefreshToken())
        .pipe(
          concatMap((newTokens: LoginResponse) => {
            if (newTokens) {
              tokenStorage.saveToken(newTokens.access_token);
              tokenStorage.saveSessionId(newTokens.sessionId);
              tokenStorage.saveExpiry(newTokens.expires_in);
              tokenStorage.saveRefreshToken(newTokens.refresh_token);

              return userService.getMyProfile();
            }
            tokenStorage.signOut();

          })
        )
        .subscribe((data: UserProfileDto) => {
          const userData = new CpUserData(data.userId, data.userName, data.firstName, data.lastName, data.pictureName);
          userData.skipMakeMoneyDesc = data.skipMakeMoneyDesc;
          userData.rate = data.rate;
          userData.rateCount = data.rateCount;
          tokenStorage.saveUserData(userData);

          let zoho;
          if (isPlatformBrowser(this.platformId) && '$zoho' in window) {
            zoho = (<any>window).$zoho;
            zoho.salesiq.visitor.name(data.firstName + ' ' + data.lastName);
            zoho.salesiq.visitor.email(data.email);
            zoho.salesiq.visitor.contactnumber(data.phoneNumber);
            zoho.salesiq.visitor.id(data.userId);
            zoho.salesiq.visitor.info({ 'phoneNumberConfirmed': data.phoneNumberConfirmed, 'emailConfirmed': data.emailConfirmed });
          }
          // refresh firebase token
          this.afMessaging.requestToken
            .subscribe(
              token => {
                this.userNotificationService.insert(
                  new UserNotificationToken(
                    null,
                    environment.clientId,
                    data.userId,
                    token
                  )
                ).subscribe();
              },
              error => {
                console.error(error);
              }
            );
          notifService.updateMessageCount();
        });

    } else {
      notifService.updateMessageCount();
    }

    this.afMessaging.messages.subscribe((message: { data, notification }) => {
      this.notifService.increaseMessageCount();
      this.notificationService.default(message.notification.body, message.notification.title)
        .subscribe(() => {
          router.navigate([message.data.Url]);
        });
    });

  }
  ngOnInit() {

    // Detects if device is on iOS
    const isIos = () => {
      if (!isPlatformBrowser(this.platformId)) { return false; }
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in (window as any).navigator) && ((window as any).navigator.standalone);

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode() && this.localStorage.getItem('ios-pwa-notif') !== 'hide') {
      this.bottomSheet.open(IosInstallComponent, {
        panelClass: ['mat-elevation-z3', 'rounded-top']
      });
    }
  }
}
