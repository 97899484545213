import { Environment } from './environment.model';
export const environment: Environment = {
  production: true,
  configApiUrl: 'https://configtest.comfypost.com',
  accountApiUrl: 'https://accounttest.comfypost.com',
  mainApiUrl: 'https://apitest.comfypost.com',
  oauthApiUrl: 'https://oauthtest.comfypost.com',
  clientId: 'db4b29c2-d2fe-e811-a5f0-e894f6105af7',
  requireLoginForAll: false,
  googleApiClientId: '215015690999-le2j9hqhpre5cdaosd9lour7ttgmal1u.apps.googleusercontent.com',
  facebookClientId: '939663442907604',
  recaptchaSiteKey: '6LcnIoUUAAAAADy7cQAfYJcPqmOYIrnRAPS2Cp52',
  appInsightsKey: '55b51513-b201-45b8-86dd-bcd767df63c0',
  appName: 'ComfyPost: International Delivery Services',
  firebase: {
    apiKey: 'AIzaSyCQwjfZmN4Kokk4bZ4CsfUjg62SYiUsmos',
    authDomain: 'comfypost-1542180329000.firebaseapp.com',
    databaseURL: 'https://comfypost-1542180329000.firebaseio.com',
    projectId: 'comfypost-1542180329000',
    storageBucket: 'comfypost-1542180329000.appspot.com',
    messagingSenderId: '215015690999',
    appId: '1:215015690999:web:0cae8070e901e237'
  }
};
