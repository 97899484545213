import { TokenStorageService, AuthService } from '@triskele/common';
import { Component, OnInit, Input, Optional } from '@angular/core';
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '@env/environment';
import { CpUserData } from '../models/user-data';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NotifService } from '../../core/notif.service';
import { AppBarIconService } from './../../core/notifications/app-bar-icon.service';
import { Location } from '@angular/common';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faBars = faBars;
  faChevronLeft = faChevronLeft;
  @Input() sideNav: MatSidenav;
  public innerWidth: any;
  showBackIcon = false;

  constructor(
    public tokenStorage: TokenStorageService<CpUserData>,
    private authService: AuthService,
    private router: Router,
    public titleService: Title,
    public notifService: NotifService,
    private appBarIconService: AppBarIconService,
    @Optional() private appInsightsService: AppInsightsService,
    private breakpointObserver: BreakpointObserver
  ) { }
  ngOnInit() {
    this.appBarIconService.currentIcon.subscribe(showBackIcon => this.showBackIcon = showBackIcon);
  }
  logout() {
    this.authService
      .removeToken(this.tokenStorage.getUserData().userId, environment.clientId)
      .subscribe(() => { this.router.navigate(['auth/login']); });
    this.tokenStorage.signOut();
    if (this.appInsightsService)
    this.appInsightsService.clearAuthenticatedUserContext();
  }
  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
  goBack() {
    const fallbackUrl = '/';
    const prevPage = window.location.href;

    setTimeout(function () {
      if (location.href === prevPage) {
        window.location.href = fallbackUrl;
      }
    }, 800);
    window.history.go(-1);
  }
}
