import { Injectable } from '@angular/core';
import { CrudService } from '@triskele/common';
import { HttpClient } from '@angular/common/http';
import { UserNotificationToken } from '../models/user-notification-token.model';
import { environment } from '@env/environment';
@Injectable()
export class UserNotificationService extends CrudService<UserNotificationToken> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.accountApiUrl + '/api/UserNotificationTokens');
  }

}
