import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-ios-install',
  templateUrl: './ios-install.component.html',
  styleUrls: ['./ios-install.component.scss']
})
export class IosInstallComponent implements OnInit {

  constructor(private bottomSheetRef: MatBottomSheetRef<IosInstallComponent>) { }

  ngOnInit() {
  }
  close() {
    this.bottomSheetRef.dismiss();
    localStorage.setItem('ios-pwa-notif', 'hide');
  }
}
