import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryService, Paged } from '@triskele/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationDTO } from '@app/shared/models/notification.model';
import { environment } from '@env/environment';

@Injectable()
export class NotificationService extends QueryService<NotificationDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient, environment.mainApiUrl + '/api/Notifications');
  }

  getNotifications(from: number,
    size: number,
    sort: string): Observable<Paged<NotificationDTO>> {
    const params = new HttpParams().set('from', from.toString())
      .set('size', size.toString()).set('sort', sort);

    return this.httpClient.get<Paged<NotificationDTO>>(
      this.baseUrl + '/My', { params: params }
    );
  }

  markAsReadNotification(notificationId) {
    return this.httpClient.put(this.baseUrl + '/MarkRead/' + notificationId, null);
  }

  deleteNotification(notificationId) {
    return this.httpClient.delete(this.baseUrl + '/my/' + notificationId);
  }
}
