import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from './core/core.module';
import { ErrorsHandler, ErrorLogService, LocalStorage, SessionStorage } from '@triskele/common';
import { environment } from '@env/environment';
import { ServiceWorkerModule, SwPush } from '@angular/service-worker';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { NotificationService } from './+user/shared/services/notification.service';
import { NotifService } from './core/notif.service';
import { IosInstallComponent } from './shared/ios-install/ios-install.component';
import { UserNotificationService } from './shared/services/user-notification-token.service';
import { CpPreloadingStrategy } from './shared/preloading-strategy';

@NgModule({
  declarations: [AppComponent, IosInstallComponent],
  imports: [
    CoreModule,
    SharedModule,
    routing,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/combined-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [

    //ConfigService,
    // get configs from server
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (config: ConfigService) => () =>
    //     config.load(
    //       environment.configApiUrl,
    //       'Share',
    //       'Triskele.Configuration'
    //     ),
    //   deps: [ConfigService],
    //   multi: true
    // },
    // get API provider addresses from server
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (clientService: ClientService) => () =>
    //     clientService.getApiProviderEndpoints(
    //       environment.accountApiUrl,
    //       environment.clientId
    //     ),
    //   deps: [ClientService],
    //   multi: true
    // },
    NotificationService,
    UserNotificationService,
    CpPreloadingStrategy,
    { provide: LocalStorage, useFactory: () => localStorage },
    { provide: SessionStorage, useFactory: () => sessionStorage },
    // add error handler
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
  ],
  entryComponents: [IosInstallComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(swPush: SwPush, notificationService: NotificationService, notifService: NotifService) {
    if (swPush.isEnabled) {
      navigator.serviceWorker
        .ready
        .then((registration) => {
          firebase.initializeApp(environment.firebase);
          firebase.messaging().useServiceWorker(registration);
        });
      swPush.notificationClicks.subscribe(arg => {
        if (arg.action === 'mark-read') {
          notificationService.markAsReadNotification(arg.notification.data.NotificationId)
            .subscribe(() => {
              if (notifService.newMessageCount > 0) {
                notifService.newMessageCount--;
              }
            }
            );
        }
      });
    }
  }
}
