import { NotificationServiceBase } from '@triskele/common';
import { Injectable } from '@angular/core';

import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private notificationService: NotificationServiceBase) {
    if (!this.swUpdate.isEnabled) {
      console.log('Nope 🙁');
    } else {
      this.swUpdate.available.subscribe(evt => {
        const snack = this.notificationService.default('New version available', 'Reload to update');

        snack
          .subscribe(() => {
            window.location.reload();
          });
      });
    }
  }
}
