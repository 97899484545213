import { NgModule, ErrorHandler } from '@angular/core';
import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { ApplicationInsightsModule, AppInsightsService } from '@markpieszak/ng-application-insights';
import { environment } from '@env/environment';
import { AppInsightErrorLogService } from './shared/services/app-insight-error-log.service';
import { ErrorLogService, ErrorsHandler } from '@triskele/common';
import { TransferHttpCacheModule } from '@nguniversal/common';

@NgModule({
  imports: [
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsightsKey
    }),
    AppModule,
    TransferHttpCacheModule
  ],

  providers: [AppInsightsService,
    {
      provide: ErrorLogService,
      useClass: AppInsightErrorLogService,
    },
     ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
