<div class="toolbar">
    <mat-toolbar *ngIf="{ signedIn: tokenStorage.isSignedIn(), data: tokenStorage.getUserData()} as user">
        <button mat-icon-button class="d-block d-md-none" (click)="showBackIcon?goBack():sideNav.open()">
      <fa-icon [icon]="showBackIcon?faChevronLeft:faBars" class="menu-bars"></fa-icon>
    </button>
        <div class="branding logo-container left d-inline-block d-md-none">
            <span class="d-block d-md-none"> {{titleService.getTitle()}}</span></div>
        <div [routerLink]="user.signedIn?['/user']:['/auth/login']" class="profile-pic d-inline-block d-md-none">
            <div class="circle " [ngStyle]="{
                'background-image': 'url(' + (user.signedIn && user.data && user.data.imageUrl && user.data.imageUrl!==''?user.data.imageUrl:'../../../assets/svg/profile-white.svg') + ')'
              }"></div>
        </div>
        <div *ngIf="user.signedIn" routerLink="/user/notifications" class="notif-icon d-inline-block d-md-none" [matBadge]="notifService.newMessageCount" matBadgeColor="accent" [matBadgeHidden]="notifService.newMessageCount==0">
            <mat-icon>notifications_none</mat-icon>
        </div>
        <div routerLink="" class="branding logo-container left d-none d-md-inline-block">
            <img src="../../../assets/svg/logo-type-complete.svg" /></div>
        <div class="menu d-none d-md-inline">
            <a mat-button class="nav-button" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"> Home </a>
            <a mat-button class="nav-button" [routerLink]="user.signedIn?['/user/add-request']:['/auth/login']" routerLinkActive="active" [queryParams]="user.signedIn?{}:{returnUrl:'/user/add-request'}"> Request a Shipment
      </a> <a mat-button class="nav-button" [routerLink]="user.signedIn?['/user/deliver-shipments']:['/auth/login']" routerLinkActive="active" [queryParams]="user.signedIn?{}:{returnUrl:'/user/deliver-shipments'}"> Deliver
        Shipments </a>
            <a mat-button class="nav-button" [routerLink]="['/shipments-list']" routerLinkActive="active"> Shipments List </a>
            <a mat-button class="nav-button" *ngIf="user.signedIn" [routerLink]="isMobile?['/user']:['/user/shipment-requests']" routerLinkActive="active"> My Account </a>
            <a mat-button class="nav-button my-notification" *ngIf="user.signedIn" routerLink="/user/notifications" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" [matBadge]="notifService.newMessageCount" matBadgeColor="accent" [matBadgeHidden]="notifService.newMessageCount==0"> My Notifications </a>
            <!-- <a mat-button class="nav-button" *ngIf="user.signedIn" (click)="logout()"> Logout </a> -->
            <a mat-button class="nav-button" *ngIf="!user.signedIn" routerLink="/auth/login" routerLinkActive="active"> Sign
        In </a>
            <a mat-button class="nav-button" *ngIf="!user.signedIn" routerLink="/auth/register" routerLinkActive="active"> Get
        started for free </a>
        </div>
        <!-- <span *ngIf="language$ | async as language">
<mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
  <mat-option *ngFor="let l of languages" [value]="l">
    {{ l.toUpperCase() }}
  </mat-option>
</mat-select>
</span> -->
    </mat-toolbar>
</div>
