import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment as env } from '@env/environment';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class TitleService {
  constructor(
    //private translateService: TranslateService,
    private title: Title,
    private breakpointObserver: BreakpointObserver
  ) { }
  setTitle(route: ActivatedRoute): void;
  setTitle(title: string): void;
  setTitle(
    routeOrTitle?: ActivatedRoute | string
  ): void {
    if (!routeOrTitle) {
      if (this.isMobile)
        this.title.setTitle('ComfyPost')
      else
        this.title.setTitle(env.appName);
      return;
    }
    if (routeOrTitle instanceof ActivatedRoute) {
      let lastChild = routeOrTitle.snapshot;
      while (lastChild.children.length) {
        lastChild = lastChild.children[0];
      }
      const { title } = lastChild.data;
      if (title) {
        if (this.isMobile)
          this.title.setTitle(title)
        else
          this.title.setTitle(`${title} - ${env.appName}`)

      }
    }
    if (routeOrTitle && (typeof routeOrTitle === "string" || routeOrTitle instanceof String)) {
      const title = routeOrTitle as string;
      if (this.isMobile)
        this.title.setTitle(title)
      else
        this.title.setTitle(`${title} - ${env.appName}`)
    }
  }
  private get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}
