import { AppModule } from './app.module';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MainLayoutComponent } from '@app/shared/layouts/main-layout.component';
import { UserLayoutComponent } from './shared/layouts/user-layout.component';
import { UserLayoutWithoutSidebarComponent } from './shared/layouts/user-layout-without-sidebar.component';
import { StaticLayoutComponent } from './shared/layouts/static-layout.component';
import { AuthGuard } from './shared/auth.guard';
import { CpPreloadingStrategy } from './shared/preloading-strategy';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    loadChildren: () => import('@app/+static/home.module').then(m => m.HomeModule)
  },
  {
    path: 'static',
    component: StaticLayoutComponent,
    loadChildren: () => import('@app/+static/static.module').then(m => m.StaticModule),
    data: { preloadOrder: 1 }
  },
  {
    path: 'user/accept-request',
    component: UserLayoutWithoutSidebarComponent,
    loadChildren: () => import('@app/+user/accept-request/accept-request.module').then(m => m.AcceptRequestModule),
    data: { preloadOrder: 1, title: 'Accept a Shipment' }
  },
  {
    path: 'user/add-request',
    component: UserLayoutWithoutSidebarComponent,
    loadChildren: () => import('@app/+user/add-request/add-request.module').then(m => m.AddRequestModule),
    canActivateChild: [AuthGuard],
    data: { preloadOrder: 1, title:'Request a Shipment' }
  },
  {
    path: 'user/deliver-shipments',
    component: UserLayoutWithoutSidebarComponent,
    loadChildren: () => import('@app/+user/deliver-shipments/deliver-shipments.module').then(m => m.DeliverShipmentsModule),
    canActivateChild: [AuthGuard],
    data: { preloadOrder: 1, title: 'Deliver Shipments' }
  },
  {
    path: 'shipment-details',
    component: UserLayoutWithoutSidebarComponent,
    loadChildren: () => import('@app/+static/shipment-details/shipment-details.module').then(m => m.ShipmentDetailsModule),
  data: { preloadOrder: 1, title: 'Shipment Details' }
  },
  {
    path: 'user/profile/:id',
    component: UserLayoutWithoutSidebarComponent,
    loadChildren: () => import('@app/+user/profile/profile.module').then(m => m.ProfileModule),
    canActivateChild: [AuthGuard],
    data: { preloadOrder: 1 }
  },
  {
    path: 'user',
    component: UserLayoutComponent,
    loadChildren: () => import('@app/+user/user.module').then(m => m.UserModule),
    canActivateChild: [AuthGuard],
    data: { preloadOrder: 1 }
  },
  {
    path: 'auth',
    component: MainLayoutComponent,
    loadChildren: () => import('@app/+auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'error',
    component: MainLayoutComponent,
    loadChildren: () => import('@app/+error/error.module').then(m => m.ErrorModule),
    data: { preloadOrder: 1 }
  },

  { path: '**', redirectTo: 'error/404' }
];

export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, {
    useHash: false,
    preloadingStrategy: CpPreloadingStrategy,
    initialNavigation: 'enabled'
});
