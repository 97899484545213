import { Injectable } from '@angular/core';
import { CrudService, TokenStorageService } from '@triskele/common';
import { NotificationAddDto } from '@app/shared/models/notification-add-dto.model';
import { CpUserData } from '@app/shared/models/user-data';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifService extends CrudService<Notification, NotificationAddDto> {
  newMessageCount: number;
  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService<CpUserData>) {
    super(http, environment.mainApiUrl + '/api/Notifications');
  }

  updateMessageCount() {
    const userData = this.tokenStorageService.getUserData();
    if (userData) {
      this.http.get<number>(this.baseUrl + '/UnreadCount').subscribe(
        count => {
          userData.newMessageCount = count;
          this.tokenStorageService.saveUserData(userData);
          this.newMessageCount = count;
        });
    }
  }
  increaseMessageCount() {
    this.newMessageCount++;
    const userData = this.tokenStorageService.getUserData();
    userData.newMessageCount = this.newMessageCount;
    this.tokenStorageService.saveUserData(userData);
  }
  deccreaseMessageCount() {
    if (this.newMessageCount > 0) {
    this.newMessageCount--;
    } else {
      this.updateMessageCount();
    }

    const userData = this.tokenStorageService.getUserData();
    userData.newMessageCount = this.newMessageCount;
    this.tokenStorageService.saveUserData(userData);
  }
  resetMessageCount() {
    this.newMessageCount = 0;
    const userData = this.tokenStorageService.getUserData();
    userData.newMessageCount = this.newMessageCount;
    this.tokenStorageService.saveUserData(userData);
  }

}
