import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TokenStorageService, AuthService, SocialAuthService } from '@triskele/common';
import { Component, Inject, PLATFORM_ID, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fadeAnimation } from '../animations/fade.animation';
import { CpUserData } from '../models/user-data';
import { Router } from '@angular/router';
import { LayoutBaseComponent } from './layout-base.comonent';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { Request } from 'express';

@Component({
  selector: 'app-user-layout-without-sidebar',
  templateUrl: './user-layout-without-sidebar.component.html',
  animations: [fadeAnimation]
})
export class UserLayoutWithoutSidebarComponent extends LayoutBaseComponent {
  faSearch = faSearch;
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    tokenStorage: TokenStorageService<CpUserData>,
    authService: AuthService,
    socialAuthService: SocialAuthService,
    router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    @Optional() @Inject(REQUEST) request: Request,
    @Optional() appInsightsService?: AppInsightsService
    ) {
    super(matIconRegistry, domSanitizer, tokenStorage, socialAuthService, authService, router, appInsightsService, platformId, request);
  }

}
