import {} from 'class-validator';

export class UserNotificationToken {
  public userNotificationTokenId: number;

  public clientId: string;

  public userId: string;

  public notificationToken: string;

  constructor(
    userNotificationTokenId?: number,
    clientId?: string,
    userId?: string,
    notificationToken?: string
    ) {
    this.userNotificationTokenId = userNotificationTokenId || 0;
    this.clientId = clientId || '';
    this.userId = userId || '';
    this.notificationToken = notificationToken || '';
  }
}
