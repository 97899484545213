import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainLayoutComponent } from './layouts/main-layout.component';
import { EmptyLayoutComponent } from './layouts/empty-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout.component';
import { UserLayoutComponent } from './layouts/user-layout.component';
import { UserLayoutWithoutSidebarComponent } from './layouts/user-layout-without-sidebar.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedModule } from './shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserService } from '@app/+user/shared/services/user.service';
import { StaticLayoutComponent } from './layouts/static-layout.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';


@NgModule({
   imports: [
      CommonModule,
      RouterModule,
      BrowserAnimationsModule,
      SharedModule.forRoot()
   ],
   providers: [
      UserService
   ],
   declarations: [
      MainLayoutComponent,
      UserLayoutComponent,
      UserLayoutWithoutSidebarComponent,
      EmptyLayoutComponent,
      AuthLayoutComponent,
      StaticLayoutComponent,
      SidebarComponent,
      HeaderComponent,
      FooterComponent,
      BackToTopComponent
   ],
   exports: []
})
export class LayoutModule {

}
