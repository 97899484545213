import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { MatDividerModule } from '@angular/material/divider';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarRatingModule, ValidationMessageModule } from '@triskele/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { CityComponent } from './city/city.component';
import { CityService } from './city/city.service';
import { PageTitleComponent } from './page-title/page-title.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Material Modules
    MatButtonModule,
    MatToolbarModule,
    MatInputModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatBadgeModule,
    MatBottomSheetModule,

    FontAwesomeModule,
    NgSelectModule,
    NgOptionHighlightModule,
    BarRatingModule,
    LoadingBarModule,
    NgxJsonLdModule,
    ValidationMessageModule
  ],
  declarations: [
    CityComponent,
    PageTitleComponent
  ],
  providers: [CityService],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Material Modules
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatBadgeModule,
    MatBottomSheetModule,

    FontAwesomeModule,
    CityComponent,
    PageTitleComponent,
    BarRatingModule,
    LoadingBarModule,
    NgxJsonLdModule,
    ValidationMessageModule
  ]
})
// export class SmartadminModule { }
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    // Forcing the whole app to use the returned providers from the AppModule only.
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
