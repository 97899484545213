import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TokenStorageService, LoginResponse, AuthService } from '@triskele/common';
import { CpUserData } from './models/user-data';
import { catchError, flatMap, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(private tokenService: TokenStorageService<CpUserData>, private router: Router,
    private authService: AuthService) { }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tokenService.isSignedIn()) {
      return true;
    }
    if (this.tokenService.getRefreshToken()) {
      return this.tryRefreshingToken().pipe(map(token => token ? true : false));
    }
    this.router.navigate(['auth/login']);
    return false;

  }
  tryRefreshingToken(): Observable<string> {
    return this.authService
      .refreshToken(environment.clientId, this.tokenService.getRefreshToken())
      .pipe(
        catchError(() => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          this.tokenService.signOut();
          this.router.navigate(['auth/login']);
          return of('');
        }),
        flatMap((newTokens: LoginResponse) => {
          if (newTokens) {
            this.tokenService.saveToken(newTokens.access_token);
            this.tokenService.saveSessionId(newTokens.sessionId);
            this.tokenService.saveExpiry(newTokens.expires_in);
            this.tokenService.saveRefreshToken(newTokens.refresh_token);
          }
          return of(newTokens.access_token);
        })
      );

  }
}
