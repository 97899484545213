import { Injectable, NgZone } from '@angular/core';
import { NotificationServiceBase, NotificationConfig } from '@triskele/common';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

@Injectable()
export class ToastrNotificationService extends NotificationServiceBase {
  constructor(
    private readonly toastrService: ToastrService
  ) {
    super();
  }

  default(message: string, title?: string) {
    return this.toastrService.show(message, title, {
      timeOut: 3000,
      enableHtml: true
    }).onTap
    .pipe(take(1));
  }

  info(message: string, title?: string) {
    return this.toastrService.info(message, title, {
      timeOut: 4000,
      enableHtml: true
    }).onTap
    .pipe(take(1));
  }
  success(message: string, title?: string) {
    return this.toastrService.success(message, title, {
      timeOut: 5000,
      enableHtml: true
    }).onTap
    .pipe(take(1));
  }

  warn(message: string, title?: string) {
    return this.toastrService.warning(message, title, {
      timeOut: 4000,
      enableHtml: true
    }).onTap
    .pipe(take(1));
  }

  error(message: string, title?: string) {
    return this.toastrService.error(message, title, {
      timeOut: 5000,
      enableHtml: true
    }).onTap
    .pipe(take(1));
  }

   show(message: string, configuration: NotificationConfig, title?: string, type?: string) {
    return this.toastrService.show(message, title, {
      timeOut: configuration.duration,
      toastClass: configuration.panelClass
    }, type).onTap
    .pipe(take(1));
  }
}
