import { Injectable, Injector, Type } from '@angular/core';
import { QueryService, ErrorLogService } from '@triskele/common';
import { CityDto } from './city-dto.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, EMPTY, of } from 'rxjs';
import { environment } from '@env/environment';
import { flatMap, catchError } from 'rxjs/operators';

@Injectable()
export class CityService extends QueryService<CityDto> {
  constructor(httpClient: HttpClient, private injector: Injector,
    private errorLogService: ErrorLogService) {
    super(httpClient, environment.mainApiUrl + '/api/Cities');
  }

  public getCitiesByName(
    from: number,
    size: number,
    cityName: string
  ): Observable<CityDto[]> {
    if (!cityName) {
      return EMPTY;
    }
    const httpParams = new HttpParams()
      .set('from', from.toString())
      .set('size', size.toString())
      .set('cityName', cityName.trim());

    return this.httpClient.get<CityDto[]>(
      this.baseUrl + '/GetCitiesByName', {
      // headers: new HttpHeaders().set('no-auth', ''),
      params: httpParams
    }
    );
  }

  public getCityById(cityId: number): Observable<CityDto> {
    if (cityId) {
      let params = new HttpParams();
      params = params.set('cityId', String(cityId));
      return this.httpClient.get<CityDto>(
        this.baseUrl + '/GetCityById', {
        // headers: new HttpHeaders().set('no-auth', ''),
        params: params
      }
      );
    }
    return EMPTY;
  }

  public getCityByName(cityName: string, countryName: string): Observable<CityDto> {
    const httpParams = new HttpParams()
      .set('cityName', cityName.trim())
      .set('countryName', countryName.trim());
    return this.httpClient.get<CityDto>(
      this.baseUrl + '/GetCityByName', {
      // headers: new HttpHeaders().set('no-auth', ''),
      params: httpParams
    });
  }
  public getCityByGeoPoint(lat: number, long: number): Observable<CityDto> {
    const options = {
      headers: new HttpHeaders().set('no-auth', '')
    };
    return this.httpClient.get<any>('https://api.opencagedata.com/geocode/v1/json?q='
      + lat + '+' + long + '&key=fd257d25c78a4b53a6af82bf117f6f11', options).pipe(
        catchError((error: Error, caught: Observable<any>) => {

            this.errorLogService.log(error);

          return of({ results: [] });
        }),
        flatMap(val => {
          if (!val || !val.results || val.results.length === 0) {
            return EMPTY;
          }
          const countryName = val.results[0].components.country;
          const cityName = val.results[0].components.city;

          if (cityName !== '') {
            return this.getCityByName(cityName.trim(), countryName.trim());
          }
          return EMPTY;
        })
      );
  }
}
