import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons/faTelegramPlane';
import { Component, OnInit } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faApple,
  faAndroid
} from '@fortawesome/free-brands-svg-icons';
import { TokenStorageService } from '@triskele/common';
import { CpUserData } from '../models/user-data';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  faFacebook = faFacebookF;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faLinkedin = faLinkedinIn;
  faYoutube = faYoutube;
  faApple = faApple;
  faAndroid = faAndroid;
  faTelegram = faTelegramPlane;
  constructor(public tokenStorage: TokenStorageService<CpUserData>) { }

  ngOnInit() { }
}
