<div class="footer d-none d-md-block" *ngIf="{ signedIn: tokenStorage.isSignedIn(), data: tokenStorage.getUserData()} as user">
    <div class="row">
        <div class="col-md-9 d-none d-md-block links">
            <a mat-button class="nav-button" routerLink="/"> Home </a>
            <a mat-button class="nav-button" routerLink="/static/about"> About </a>
            <a mat-button class="nav-button" routerLink="/static/terms"> Terms </a>
            <a mat-button class="nav-button" routerLink="/static/policy"> Privacy Policy </a>
            <a mat-button class="nav-button" routerLink="/static/help"> FAQ & Help </a>
            <a mat-button class="nav-button" routerLink="/static/how-it-works"> How It Works </a>
            <a mat-button class="nav-button" *ngIf="!user.signedIn" routerLink="/auth/login" routerLinkActive="active"> Sign
        In </a>
            <a mat-button class="nav-button" *ngIf="!user.signedIn" routerLink="/auth/register" routerLinkActive="active"> Get
        started for free </a>
        </div>
        <div class="col-md-3 col-12">
            <span class="d-none d-md-block">Copyright &copy; 2020, ComfyPost Inc. All rights reserved. </span>
        </div>
    </div>
    <div class="row">
        <div class="col-12 social-net">
            <a target="_blank" href="https://www.facebook.com/ComfyPost" rel="noreferrer">
                <fa-icon [icon]="faFacebook" style="font-size: 15px;"></fa-icon>
            </a>
            <a target="_blank" href="https://twitter.com/comfy_post" rel="noreferrer">
                <fa-icon [icon]="faTwitter" style="font-size: 14px;margin-top: 2px;"></fa-icon>
            </a>
            <a target="_blank" href="https://www.instagram.com/Comfy_Post_Inc" rel="noreferrer">
                <fa-icon [icon]="faInstagram" style="font-size: 15px;"></fa-icon>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/comfypost/" rel="noreferrer">
                <fa-icon [icon]="faLinkedin" style="font-size: 14px;margin-left: 2px; margin-top: 2px;"></fa-icon>
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCPKyxbAPfnb9Q0McEcTPsww" rel="noreferrer">
                <fa-icon [icon]="faYoutube" style="font-size: 14px;margin-top: 2px;"></fa-icon>
            </a>
            <a target="_blank" href="https://t.me/ComfyPostGroup" rel="noreferrer">
                <fa-icon [icon]="faTelegram" style="margin-top: 1px;font-size: 15px;margin-left: -1px;"></fa-icon>
            </a>
            <!-- <a href="#">
                <fa-icon [icon]="faApple" style="margin-top:-1px;"></fa-icon>
            </a>
            <a href="#">
                <fa-icon [icon]="faAndroid" style="margin-top:-1px;"></fa-icon>
            </a> -->
        </div>
    </div>
</div>
