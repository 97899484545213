<div class="header"> My Account </div>
<ul>
    <!-- <li>
        <a routerLink="/user" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <mat-icon svgIcon="cp-speedometer"></mat-icon>
            My Dashboard
        </a>
    </li> -->
    <li>
        <a routerLink="/user/shipment-requests" routerLinkActive="active">
            <mat-icon svgIcon="cp-shipment-requests" style="width:18px"></mat-icon> My Shipment Requests
        </a>
        <!-- <li>
                    <a routerLink="/user/offers" routerLinkActive="active">
                      My Received Offers
            </a>
                </li> -->
    </li>
    <li>
        <a routerLink="/user/deliveries" routerLinkActive="active">
            <mat-icon svgIcon="cp-deliveries" style="height:24px"></mat-icon> What I Deliver
        </a>
        <!-- <li>
                    <a routerLink="/user/tracking" routerLinkActive="active">
              Tracking
            </a>
                </li> -->
    </li>
    <li>
        <a routerLink="/user/notifications" routerLinkActive="active">
            <mat-icon svgIcon="cp-notif"></mat-icon> My Notifications
        </a>
    </li>
    <li>
        <a routerLink="/user/profile" routerLinkActive="active">
            <mat-icon svgIcon="cp-profile"></mat-icon> My Profile
        </a>
    </li>
    <li>
        <a (click)="logout()" style="cursor: pointer;">
            <mat-icon svgIcon="cp-signout" style="height:16px"></mat-icon> Logout
        </a>
    </li>
</ul>