<div class="theme-wrapper light">
    <mat-sidenav-container *ngIf="{
      signedIn: tokenStorage.isSignedIn(),
      data: tokenStorage.getUserData()
    } as user">
        <ngx-loading-bar [includeSpinner]="false" color="#fff" height="3px"></ngx-loading-bar>
        <mat-sidenav #sidenav mode="over">
            <div class="profile-div">
                <div class="prop">
                    <div class="pic">
                        <div class="circle" [ngStyle]="{
                            'background-image': 'url(' + (user.signedIn && user.data && user.data.imageUrl && user.data.imageUrl!==''?user.data.imageUrl:'../../../assets/svg/profile-white.svg') + ')'
                          }"></div>
                    </div>
                    <div class="name" *ngIf="user.signedIn && user.data"> {{ user.data.firstName + ' ' + user.data.lastName }}
                    </div>
                    <div class="email" *ngIf="user.signedIn && user.data"> {{ user.data.username }} </div>
                </div>
                <button *ngIf="user.signedIn" (click)="logout()" mat-flat-button color="primary" class="signout-button"> Sign
          Out </button>
                <div *ngIf="!user.signedIn" class="login-container">
                    <a (click)="sidenav.close()" routerLink="/auth/login" routerLinkActive="active"> Sign In </a> &nbsp; &#124; &nbsp; <a (click)="sidenav.close()" routerLink="/auth/register" routerLinkActive="active"> Get started for
            free </a>
                </div>
            </div>
            <mat-nav-list>
                <a mat-list-item (click)="sidenav.close()" routerLink="/" routerLinkActive="active">
                    <mat-icon svgIcon="cp-home"></mat-icon> Home
                </a>
                <a mat-list-item (click)="sidenav.close()" [routerLink]="user.signedIn ? ['/user/add-request'] : ['/auth/login']" routerLinkActive="active" [queryParams]="
            user.signedIn ? {} : { returnUrl: '/user/add-request' }
          ">
                    <mat-icon svgIcon="cp-package"></mat-icon> Request a Shipment
                </a>
                <a mat-list-item (click)="sidenav.close()" [routerLink]="user.signedIn?['/user/deliver-shipments']:['/auth/login']" routerLinkActive="active" [queryParams]="user.signedIn?{}:{returnUrl:'/user/deliver-shipments'}">
                    <mat-icon svgIcon="cp-deliver-shipment"></mat-icon> Deliver Shipments
                </a>
                <a mat-list-item (click)="sidenav.close()" [routerLink]="['/shipments-list']" routerLinkActive="active">
                    <mat-icon svgIcon="cp-money-bag"></mat-icon> Shipments List
                </a>
                <mat-divider *ngIf="user.signedIn"></mat-divider>
                <a *ngIf="user.signedIn" mat-list-item (click)="sidenav.close()" routerLink="/user" routerLinkActive="active">
                    <mat-icon svgIcon="cp-star"></mat-icon> My Account
                </a>
                <a *ngIf="user.signedIn" mat-list-item (click)="sidenav.close()" routerLink="/user/shipment-requests" routerLinkActive="active">
                    <mat-icon svgIcon="cp-shipment-requests" style="width: 16px;margin-left: -3px;"></mat-icon> My Shipment Requests
                </a>
                <a *ngIf="user.signedIn" mat-list-item (click)="sidenav.close()" routerLink="/user/deliveries" routerLinkActive="active">
                    <mat-icon svgIcon="cp-deliveries" style="width: 18px;margin-left: -3px;"></mat-icon> What I Deliver
                </a>
                <mat-divider></mat-divider>
                <a mat-list-item (click)="sidenav.close()" routerLink="/static/about" routerLinkActive="active">
                    <mat-icon svgIcon="cp-about"></mat-icon> About Us
                </a>
                <a mat-list-item (click)="sidenav.close()" routerLink="/static/how-it-works" routerLinkActive="active">
                    <mat-icon svgIcon="cp-logo"></mat-icon> How ComfyPost Works
                </a>
                <a mat-list-item (click)="sidenav.close()" routerLink="/static/help" routerLinkActive="active">
                    <mat-icon svgIcon="cp-question"></mat-icon> FAQ & Help
                </a>
                <a mat-list-item (click)="sidenav.close()" routerLink="/static/terms" routerLinkActive="active">
                    <mat-icon svgIcon="cp-terms"></mat-icon> Terms Of Use
                </a>
                <a mat-list-item (click)="sidenav.close()" routerLink="/static/policy" routerLinkActive="active">
                    <mat-icon svgIcon="cp-policy"></mat-icon> Privacy Policy
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <div>
            <app-header [sideNav]="sidenav" class="static-header"></app-header>
            <div class="content">
                <router-outlet (activate)="onActivate($event)" #o="outlet"></router-outlet>
            </div>
            <app-footer class="static-footer"></app-footer>
        </div>
    </mat-sidenav-container>
    <app-back-to-top>
    </app-back-to-top>
</div>
