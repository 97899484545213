import { Component, OnInit, Optional } from '@angular/core';
import { TokenStorageService, AuthService, SocialAuthService } from '@triskele/common';
import { CpUserData } from '../models/user-data';
import { AppInsightsService } from '@markpieszak/ng-application-insights';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor(public tokenStorage: TokenStorageService<CpUserData>,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    @Optional() private appInsightsService: AppInsightsService,
    private router: Router,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    matIconRegistry
      .addSvgIcon(
        'cp-signout',
        domSanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/svg/signout.svg'
        )
      );
  }

  ngOnInit() { }
  logout() {
    this.socialAuthService.signOut();
    this.authService
      .removeToken(this.tokenStorage.getUserData().userId, environment.clientId)
      .subscribe(() => { this.router.navigate(['auth/login']); });
    this.tokenStorage.signOut();
    if (this.appInsightsService)
    this.appInsightsService.clearAuthenticatedUserContext();
  }
}
