import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { PreloadingStrategy, Route } from '@angular/router';

@Injectable()
export class CpPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preloadOrder'] && route.data['preloadOrder'] > 0) {
      const delay: number = Number(route.data['preloadOrder']) * 2000;
      //console.log('preload called on ' + route.path + ' delay is ' + delay);
      return timer(delay).pipe(
        flatMap(_ => {
          // console.log('Loading now ' + route.path);
          return loadMe();
        }));
    } else {
      return loadMe();
    }
  }

}
